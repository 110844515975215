$(document).ready(function () {
  ("use strict");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  var toggleMenu = document.querySelector(".navigation button");
  var menu = document.querySelector("#menu");

  toggleMenu.addEventListener("click", function () {
    var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
    toggleMenu.setAttribute("aria-expanded", !open);
    menu.hidden = !menu.hidden;
  });

  $(".hamburger").on("click", function () {
    // afegir classe al botó
    $(this).toggleClass("is-active");
    // afegir classe al body
    // $("body").toggleClass("js-menu-open");
  });

  $(".main-nav button").click(function () {
    $(this).prev().toggleClass("is-active");
    return true;
  });


  // -------
  // Sub nav
  // -------

  $(".sub-nav > .has-submenu > a").click(function () {
    $(this).parent().toggleClass("is-active");
    $(this).next().toggleClass("is-visible");
    $(this).css('outline', 'none');
    return false;
  });

  $(".sub-nav > .has-submenu a.active").parent().parent().addClass('is-visible').parent().addClass('is-active');

  $(".sub-navquefem > li > ul > .has-submenu > a").click(function () {
    $(this).parent().toggleClass("is-active");
    $(this).next().toggleClass("is-visible");
    $(this).css('outline', 'none');
    return false;
  });

  $(".sub-navquefem li.has-submenu a.active").parent().parent().addClass('is-visible').parent().addClass('is-active');

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // ----------
  // scroll top
  // ----------

  $(window).scroll(function () {
    if ($(this).scrollTop() > 400) {
      $(".scrolltop").fadeIn();
    } else {
      $(".scrolltop").fadeOut();
    }
  });

// -----------
  // Collapsible (https://inclusive-components.design/collapsible-sections/)
  // -----------


  //Recorrem les opcions per saber si hem de desplegar aquella secció
  $('.collapsible li input').each(function(){
    var attr = $(this).attr('checked');
    if (typeof attr !== 'undefined' && attr !== false) {
      var selbtn = $(this).parents('fieldset').find('button');
      var seltarget = $(this).parents('fieldset').find('div');
      selbtn.attr("aria-expanded", 'true');
      seltarget.show();
    }
  });

  if ($(".collapsible")[0]) {
    (function () {
      var headings = document.querySelectorAll(".collapsible__title");
      Array.prototype.forEach.call(headings, function (h) {
        var btn = h.querySelector("button");
        var target = h.nextElementSibling;

        btn.onclick = function (event) {
          //Evitem que els botons del desplegable enviin el form
          event.preventDefault();
          var expanded = btn.getAttribute("aria-expanded") === "true";
          btn.setAttribute("aria-expanded", !expanded);
          target.hidden = expanded;
        };
      });
    })();
  }


  // ------------
  // AppendAround
  // ------------
  $(".cc").appendAround();
  $(".s").appendAround();
  $(".legal").appendAround();

  // ----------
  // Print link
  // ----------
  /*addPrintLink function by Roger Johansson, www.456bereastreet.com*/
  var addPrintLink = {
    init: function (sTargetEl, sLinkText) {
      if (!document.getElementById || !document.createTextNode) {
        return;
      } // Check for DOM support
      if (!document.getElementById(sTargetEl)) {
        return;
      } // Check that the target element actually exists
      if (!window.print) {
        return;
      } // Check that the browser supports window.print
      var oTarget = document.getElementById(sTargetEl);
      var oLink = document.createElement("a");
      oLink.id = "print-link"; // Give the link an id to allow styling
      oLink.href = "#"; // Make the link focusable for keyboard users
      oLink.appendChild(document.createTextNode(sLinkText));
      oLink.onclick = function () {
        window.print();
        return false;
      }; // Return false prevents the browser from following the link and jumping to the top of the page after printing
      oTarget.appendChild(oLink);
    },

  };
  if (typeof printText !== 'undefined' && printText != '') {
    addPrintLink.init("print", printText);
  }

  // ------
  // slider
  // ------
  $(".slider").slick({
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false
  });
  $(".slider-news").slick({
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    autoplaySpeed: 6000,
    arrows: false,
    dots: true
  });

  // --------
  // headroom
  // --------
  // $(".main-header").headroom();

  $( "#slider-range" ).slider({
    range: true,
    min: $( "#slider-range" ).data('min'),
    max: $( "#slider-range" ).data('max'),
    values: [ $( "#slider-range" ).data('currentmin'), $( "#slider-range" ).data('currentmax') ],
    slide: function( event, ui ) {
      $( "#rang_anys" ).text( ui.values[ 0 ] + " - " + ui.values[ 1 ] );
      $( "#any_min" ).val( ui.values[ 0 ] );
      $( "#any_max" ).val( ui.values[ 1 ] );
    }
  });
  $( "#rang_anys" ).text($( "#slider-range" ).slider( "values", 0 ) + " - " + $( "#slider-range" ).slider( "values", 1 ) );
  $( "#any_min" ).val( $( "#slider-range" ).slider( "values", 0 ) );
  $( "#any_max" ).val( $( "#slider-range" ).slider( "values", 1 ) );
});
